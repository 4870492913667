* {
  font-family: "Josefin Sans", sans-serif;
}

body {
  height: 100vh;
  margin: 0;
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;
}

h1 {
  font-size: xxx-large;
  margin-bottom: 1rem;
}

h2 {
  font-size: x-large;
  margin-top: 0;
}

h3 {
  font-size: xx-large;
}

a {
  color: #717171;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-visible,
  &:active,
  &:visited {
    cursor: pointer;
    color: #717171;
  }
}

button {
  color: white;
  background-color: black;
  border: 4px black solid;
  border-radius: 20px;
  font-size: larger;
  padding: 0.25em 1em;
  font-weight: 900;
  height: 2.5rem;
  transition: 0.5s all;
  margin: 0 10px;

  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-visible,
  &:active {
    outline: none;
  }
}

button:hover {
  filter: drop-shadow(5px 2px #333333);
  color: black;
  background-color: white;
  border: 3px solid black;
  transform: translate(-5px, -2px);
  cursor: pointer;
}

#root {
  transform: translate(0, -3em);
}

.App {
  display: grid;
  grid-template-rows: repeat(3, 4.5em);
}

form {
  margin: 1em 0;
}

.form-group {
  display: grid;
  text-align: left;

  label {
    margin-bottom: 5px;
  }

  label,
  input {
    font-size: larger;
  }

  input {
    padding-left: 8px;
    border-radius: 10px;
    height: 1.5em;
    justify-content: center;
    align-items: center;

    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      outline: none;
    }
  }

  textarea {
    font-size: medium;
    padding: 7px;
  }

  .hidden {
    height: 35px;
  }

  .visible {
    padding: 3px 7px 11px;
    margin: 4px 0;
    color: red;
  }
}

.flash-message {
  background-color: rgba(0, 255, 169, 0.66);
  padding: 1em;
}

.no-flash-message {
  background-color: transparent;
  padding: 1.5em 1em;
}

.flash-message,
.no-flash-message {
  font-size: larger;
  border-radius: 10px;
}

.relief-fund-desc {
  text-align: justify;
  word-break: keep-all;
  color: #717171;
  font-size: medium;
  width: 367px;
  margin: auto;
}

h4 {
  text-align: justify;
  word-break: keep-all;
  width: 367px;
}

@media only screen and (max-width: 500px) {
  /* For mobile phones: */
  h1 {
    font-size: xx-large;
  }

  h2,
  h3,
  h4 {
    width: 300px !important;
  }

  h3 {
    font-size: x-large;
  }

  button {
    font-size: medium;
    margin: 5px;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    width: 300px !important;
  }
}
